import { matomoEventTypeMap } from 'shared/utils/matomoConfig';
import { wrapLink } from './pages';

export const privacyPolicyLink = 'https://www.thoughtworks.com/cn/privacy-policy';
export const sitemapData = [
  {
    label: { textKey: 'siteMap.recruitment' },
    list: [
      { textKey: 'siteMap.aboutUs', link: wrapLink('about'), matomoEventType: matomoEventTypeMap.footer.aboutUs },
      { textKey: 'siteMap.submitResume', link: wrapLink('recruitment'), matomoEventType: matomoEventTypeMap.footer.joinUs },
      { textKey: 'siteMap.superX', link: wrapLink('super-x'), matomoEventType: matomoEventTypeMap.footer.superX },
      { textKey: 'siteMap.training', link: wrapLink('training'), matomoEventType: matomoEventTypeMap.footer.training },
      { textKey: 'siteMap.notice', link: wrapLink('notice'), matomoEventType: matomoEventTypeMap.footer.notice },
      { textKey: 'siteMap.faq', link: wrapLink('faq'), matomoEventType: matomoEventTypeMap.footer.faq },
    ],
  },
  {
    label: { textKey: 'siteMap.knowMore' },
    list: [
      { textKey: 'siteMap.officialWebsite', link: 'https://thoughtworks.com/', matomoEventType: matomoEventTypeMap.footer.thoughtWorksWeb },
      {
        textKey: 'siteMap.insight',
        link: 'https://www.thoughtworks.com/zh-cn/insights',
        matomoEventType: matomoEventTypeMap.footer.thoughtWorksInsight,
      },
    ],
  },
  {
    label: { textKey: 'siteMap.information' },
    list: [
      { textKey: 'siteMap.policy', link: privacyPolicyLink, matomoEventType: matomoEventTypeMap.footer.thoughtWorksPolicy },
      {
        textKey: 'siteMap.guideline',
        link: 'https://www.thoughtworks.com/content/dam/thoughtworks/documents/guide/tw_guide_code_of_conduct_cn.pdf',
        matomoEventType: matomoEventTypeMap.footer.thoughtWorksCodeOfConduct,
      },
    ],
  },
  {
    label: { textKey: 'siteMap.contactUs' },
    list: [
      {
        textKey: 'siteMap.emailContact',
        link: 'mailto:campusrecruitingteam@thoughtworks.com',
        matomoEventType: matomoEventTypeMap.footer.emailsTo,
      },
    ],
  },
];
